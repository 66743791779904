/*
 * @Author: zhoubiao
 * @Date: 2021-04-09 14:38:45
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-17 14:18:17
 * @FilePath: \vue-element-admin\src\utils\request.js
 */
import axios from 'axios';
import { getToken } from './auth';

// create an axios instance
const service = axios.create({
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 50000, // request timeout
});

// request interceptor
service.interceptors.request.use(
    (config) => {
        // do something before request is sent
        // if (store.getters.token) {
        // let each request carry token
        // ['X-Token'] is a custom headers key
        // please modify it according to the actual situation
        const accessToken = getToken();
        accessToken && (config.headers.Authorization = 'Bearer ' + accessToken);
        // }
        return config;
    },
    (error) => {
        // do something with request error
        console.log(error); // for debug
        return Promise.reject(error);
    },
);

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    (response) => {
        const res = response.data;

        // 50008: Illegal token; 50012: Other clients logged in; 401: Token expired;
        if (res.code === 50008 || res.code === 401) {
            // to re-login
            // MessageBox.confirm('您的登录信息已经失效', '提示', {
            //     confirmButtonText: '重新登陆',
            //     cancelButtonText: '取消',
            //     type: 'warning',
            // }).then(() => {
            //     let baseUrl = process.env.REACT_APP_OFFICIAL_URL;
            //     let url = baseUrl;
            //     window.location.href = url;
            // });
        }

        // if the custom code is not\ 200, it is judged as an error.
        if (res.code === 500) {
            // Message({
            //     message: res.message || 'Error',
            //     type: 'error',
            //     duration: 5 * 1000,
            // });
            return Promise.reject(new Error(res.message || 'Error'));
        } else {
            return res;
        }
    },
    (error) => {
        // console.log('err' + error);
        // let { message } = error;
        // if (message == 'Network Error') {
        //     message = '后端接口连接异常';
        // } else if (message.includes('timeout')) {
        //     message = '系统接口请求超时';
        // } else if (message.includes('Request failed with status code')) {
        //     message = '系统接口' + message.substr(message.length - 3) + '异常';
        // }
        // Message({
        //     message: message,
        //     type: 'error',
        //     duration: 5 * 1000,
        // });
        return Promise.reject(error);
    },
);

export default service;
