export default {
    en: {
        access: 'Please use a computer to access',
        delete: 'Delete a subtitle',
        update: 'Update a subtitle',
        add: 'Add a subtitle',
        merge: 'merge two subtitle',
        your: 'Your Subtitle Text',
        download: 'Download vtt subtitle',
        removeEmpty: 'Remove empty subtitle',
        removeAll: 'Remove all subtitle',
        offset: 'Time offset',
        translate: 'Translate subtitles',
        translateLenght: 'Currently translates up to 1000 subtitles at a time',
        translateProgress: 'Translation is in progress...',
        history: 'History rollback',
        historyEmpty: 'History is empty',

        description: 'A Online Subtitle Editor: vtt、srt、ass',
        uploadSubtitle: 'Open subtitle file',
        uploadSubtitleErr: 'Only the following subtitle are supported: vtt、srt、ass',
        uploadVideo: 'Open video file',
        uploadVideoErr: 'This video format is not supported',
        btnUploadSubtitle: 'Open Subtitle',
        btnUploadVideo: 'Open Video',
        btnDownloadSubtitle: 'Download Subtitle',

        startTime: 'Start time format needs to match like: [00:00:00.000]',
        endTime: 'End time format needs to match like: [00:00:00.000]',
        greater: 'Start time cannot be greater than or equal to the end time',
        overlaps: 'This time overlaps with the time of the previous one',
        start: 'Start',
        end: 'End',
        duration: 'Duration',
        text: 'Text',
        operation: 'Operation',

        durationLimit: 'Set the time longer than the video duration',

        btnAddSubtitle: 'Add Subtitle',
        btnTimeOffsetMinus: 'Time Offset -100ms',
        btnTimeOffsetPlus: 'Time Offset +100ms',
        btnUndo: 'Undo',
        btnRemoveAll: 'Remove All Subtitle',
        btnRemoveEmpty: 'Remove Empty Subtitle',
        btnRemoveCache: 'Remove Cache',
        btnBatchTranslate: 'Batch Translate',
        to: 'To',
        use: 'Use',
        btnOverallOffset: 'Timeline overall offset',
        on: 'On',
        off: 'Off',

        moveInvalid: 'Subtitle parameters are invalid, please try again',
        moveAcross: "Can't move across subtitles, please try again",
        deleteLine: 'Delete line',
        insertBefore: 'Insert before',
        insertAfter: 'Insert after',
        mergeNext: 'Merge with next',

        waveformBuildEnd: 'The waveform generation succeeded',
    },
    zh: {
        access: '请使用电脑访问',
        delete: '删除一个字幕',
        update: '更新一个字幕',
        add: '添加一个字幕',
        merge: '合并两个字幕',
        your: '你的字幕文本',
        download: '下载 VTT 字幕',
        removeEmpty: '删除空的字幕',
        removeAll: '删除全部字幕',
        offset: '时间偏移',
        translate: '翻译字幕',
        translateLenght: '当前每次最大只能翻译1000条字幕',
        translateProgress: '字幕翻译正在进行中...',
        history: '历史回滚',
        historyEmpty: '历史为空',

        description: '一个在线字幕编辑器：vtt、srt、ass',
        uploadSubtitle: '打开字幕文件',
        uploadSubtitleErr: '当前只支持一下字幕: vtt、srt、ass',
        uploadVideo: '打开视频文件',
        uploadVideoErr: '该视频格式不支持',
        btnUploadSubtitle: '打开字幕',
        btnUploadVideo: '合成视频',
        btnDownloadSubtitle: '下载字幕',

        startTime: '开始时间的格式需要类似: [00:00:00.000]',
        endTime: '结束时间的格式需要类似: [00:00:00.000]',
        greater: '开始时间不能大于结束时间',
        overlaps: '这个时间与上一条字幕时间重叠',
        start: '开始',
        end: '结束',
        duration: '时长',
        text: '文本',
        operation: '操作',

        durationLimit: '设置时间大于视频的时长',

        btnAddSubtitle: '添加字幕',
        btnTimeOffsetMinus: '时间偏移 -100 毫秒',
        btnTimeOffsetPlus: '时间偏移 +100毫秒',
        btnUndo: '回滚',
        btnRemoveAll: '删除全部字幕',
        btnRemoveEmpty: '删除空的字幕',
        btnRemoveCache: '删除缓存',
        btnBatchTranslate: '批量翻译',
        to: '到',
        use: '使用',
        btnOverallOffset: '时间轴整体偏移',
        on: '开',
        off: '关',
        moveInvalid: '字幕参数不合法，请重试',
        moveAcross: '不能跨字幕移动，请重试',
        deleteLine: '删除字幕',
        insertBefore: '之前插入',
        insertAfter: '之后插入',
        mergeNext: '合并下条',
        waveformBuildEnd: '波形图生成成功',
    },
};
