/*
 * @Author: zhoubiao
 * @Date: 2021-05-08 16:50:49
 * @LastEditors: Biao
 * @LastEditTime: 2021-10-14 17:44:42
 * @FilePath: \vue-element-admin\src\api\user.js
 */
import request from '../utils/request';

export function getSubtitleDetail(data) {
    console.log(process.env.REACT_APP_BASE_API);
    // /
    return request({
        url: process.env.REACT_APP_BASE_API + `/qm/getSubtitleUrl/${data}`,
        method: 'get',
    });
}

export function getInfo(data) {
    return request({
        url: process.env.REACT_APP_BASE_API + '/login/user_info',
        method: 'get',
        params: {
            ...data,
        },
    });
}

export function logout() {
    return request({
        url: process.env.REACT_APP_BASE_API + '/logout',
        method: 'post',
    });
}
export function CaptionEmbedding(data) {
    return request({
        url: process.env.REACT_APP_BASE_API + '/qm/CaptionEmbedding',
        method: 'post',
        data,
    });
}
