/*
 * @Author: your name
 * @Date: 2021-09-16 16:06:25
 * @LastEditTime: 2021-09-17 17:58:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \greengage-web\src\index.js
 */
import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';
import toastr from 'toastr';
import NProgress from 'nprogress';
import * as serviceWorker from './serviceWorker';
import { isMobile } from './utils';
import { setTranslations, t } from 'react-i18nify';
import i18n from './i18n';
import 'normalize.css';
import './fontello/css/fontello.css';
import 'toastr/build/toastr.css';
import 'nprogress/nprogress.css';
import 'react-virtualized/styles.css';
import 'antd/dist/antd.dark.css';
// import 'artplayer-react/dist/artplayer-react.css';

console.log(process.env);

setTranslations(i18n);
toastr.options.timeOut = 3000;
toastr.options.hideDuration = 300;
NProgress.configure({ minimum: 0, showSpinner: false });
ReactDOM.render(isMobile() ? t('access') : <App />, document.getElementById('root'));
serviceWorker.unregister();
