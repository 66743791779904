import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { t, Translate } from 'react-i18nify';
import toastr from 'toastr';
import NProgress from 'nprogress';
import { readSubtitleFromFile, urlToArr, vttToUrl, getExt } from '../utils';
import { version } from '../../package.json';
import { message, Button, Space, Modal, Form, Input } from 'antd';
import { CaptionEmbedding } from '../api/index';
const Wrapper = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid rgb(10, 10, 10);
    background-color: rgb(28, 32, 34);

    .left {
        display: flex;
        align-items: center;
        height: 100%;
        padding-left: 20px;
    }

    .right {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .links {
            margin-right: 20px;
            a {
                margin-left: 20px;
                color: #ccc;
                text-decoration: none;

                &:hover {
                    color: #fff;
                }
            }
        }
    }
`;

const Logo = styled.a`
    color: #fff;
    font-size: 16px;
    text-decoration: none;

    .beta {
        margin-left: 5px;
        color: #67bf00;
        font-size: 12px;
    }
`;

const Description = styled.span`
    font-size: 12px;
    font-style: italic;
    margin-left: 10px;
    opacity: 0.4;
`;

const Btn = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 10px;
    width: 170px;
    cursor: pointer;
    overflow: hidden;
    color: #ccc;
    background-color: rgb(46, 54, 60);
    border-left: 1px solid rgb(10, 10, 10);
    transition: all 0.2s ease;

    &:hover {
        color: #fff;
        background-color: rgb(66, 82, 95);
    }

    i {
        margin-right: 5px;
    }
`;

const File = styled.input`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
`;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

const CollectionsPage = (props) => {
    console.log('props', props);
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const onCreate = (values) => {
        setVisible(false);
    };
    return (
        <div className="right">
            <Btn
                onClick={() => {
                    form.resetFields();

                    setVisible(true);
                }}
            >
                <i className="icon-ok"></i>
                <Translate value="btnUploadVideo" />
            </Btn>

            <Modal
                visible={visible}
                title="合成视频"
                okText="创建"
                cancelText="取消"
                onCancel={() => {
                    setVisible(false);
                }}
                onOk={() => {
                    form.validateFields()
                        .then((values) => {
                            props.toggle(true);
                            let params = { ...values };
                            params.subtitleId = props.subtitleId;
                            params.list = props.subtitles;
                            CaptionEmbedding(params)
                                .then((res) => {
                                    if (res.code == 200) {
                                        message.success(res.message);
                                    } else {
                                        message.error(res.message);
                                    }
                                    onCreate(values);
                                    props.toggle(false);
                                })
                                .catch((err) => {
                                    props.toggle(false);
                                    message.error('操作失败');
                                });
                            // onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                            // onCreate(values);
                        });
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                    }}
                >
                    <Form.Item
                        name="name"
                        label="视频名称"
                        rules={[
                            {
                                required: true,
                                message: '请输入视频吗名称!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
export default class Header extends React.Component {
    state = {
        open: false,
    };
    $subname = React.createRef();
    $video = React.createRef();

    uploadSubtitle() {
        if (this.$subtitle.current && this.$subtitle.current.files[0]) {
            NProgress.start().set(0.5);
            const file = this.$subtitle.current.files[0];
            const type = getExt(file.name);
            if (['vtt', 'srt', 'ass'].includes(type)) {
                readSubtitleFromFile(file, type)
                    .then((data) => {
                        const subtitleUrl = vttToUrl(data);
                        urlToArr(subtitleUrl)
                            .then((subtitles) => {
                                this.props.updateSubtitles(subtitles, true).then(() => {
                                    toastr.success(`${t('uploadSubtitle')}: ${subtitles.length}`);
                                    NProgress.done();
                                });
                            })
                            .catch((error) => {
                                toastr.error(error.message);
                                NProgress.done();
                                throw error;
                            });
                    })
                    .catch((error) => {
                        toastr.error(error.message);
                        NProgress.done();
                        throw error;
                    });
            } else {
                NProgress.done();
                toastr.error(t('uploadSubtitleErr'));
            }
        }
    }

    uploadVideo() {
        if (this.$video.current && this.$video.current.files[0]) {
            NProgress.start().set(0.5);
            const file = this.$video.current.files[0];
            const $video = document.createElement('video');
            const canPlayType = $video.canPlayType(file.type);
            if (canPlayType === 'maybe' || canPlayType === 'probably') {
                const url = URL.createObjectURL(file);
                this.props.updateVideoUrl(url);
                toastr.success(`${t('uploadVideo')}: ${(file.size / 1024 / 1024).toFixed(3)}M`);
            } else {
                toastr.error(`${t('uploadVideoErr')}: ${file.type || 'unknown'}`);
            }
            NProgress.done();
        }
    }
    showModal() {
        this.setState({
            open: true,
        });
    }
    handleCancel() {
        this.setState({
            open: false,
        });
    }
    handleOk() {
        this.setState({
            open: false,
        });
    }
    render() {
        return (
            <Wrapper>
                <div className="left">
                    <Logo href="javascript:void(false)">
                        清媒宝字幕编辑器
                        {/* <span className="beta">Beta {version}</span> */}
                        <Description>
                            <Translate value="description" />
                        </Description>
                    </Logo>
                </div>
                <div className="right">
                    <Btn>
                        <i className="icon-upload"></i>
                        <Translate value="btnUploadSubtitle" />
                        <File
                            className="uploadSubtitle"
                            type="file"
                            name="file"
                            ref={this.$subtitle}
                            onChange={() => this.uploadSubtitle()}
                        />
                    </Btn>
                    <Btn onClick={this.props.downloadSubtitles.bind(this)}>
                        <i className="icon-download"></i>
                        <Translate value="btnDownloadSubtitle" />
                    </Btn>

                    <CollectionsPage
                        subtitles={this.props.subtitles}
                        subtitleId={this.props.subtitleId}
                        toggle={this.props.toggle.bind(this)}
                    ></CollectionsPage>
                </div>
            </Wrapper>
        );
    }
}
