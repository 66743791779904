/*
 * @Author: zhoubiao
 * @Date: 2021-04-09 14:38:45
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-26 09:59:49
 * @FilePath: \vue-element-admin\src\utils\auth.js
 */
import Cookies from 'js-cookie';
//token 键名
const TokenKey = 'accessToken';
export function getToken() {
    return Cookies.get(TokenKey);
}

export function setToken(token) {
    return Cookies.set(TokenKey, token, { domain: process.env.REACT_APP_DOMAIN });
}

export function removeToken() {
    return Cookies.remove(TokenKey, { domain: process.env.REACT_APP_DOMAIN });
}
